import React, {useEffect, useRef, useState} from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {validateEmail, validatePhone} from "../../../core/business/helpers/input";
import {getPropertyNamesByUrl} from "../../business/redux/reducers/property/actions";
import {ErrorComponent} from "../../components/errors";
import {getUnitList} from "../../business/redux/reducers/unit/actions";
import Autocomplete from '@mui/material/Autocomplete';
import {StoreModel} from "../../business/models/store";
import {IonCol, IonContent, IonFooter, IonGrid, IonImg, IonRow} from "@ionic/react";
import {
  ADD_YOUR_NAME_ROUTE,
  COMMUNITY_INFORMATION_ROUTE,
  MOVE_IN_DATE_INFORMATION_ROUTE
} from "../../business/enums/routes";
import {Wrapper} from "../../../core/styles";
import {apartmentStyle} from "./styles";
import {colors} from "../../business/constants/global";
import {ICON_APARTMENT} from "../../images";
import moment from "moment";
import TextField from "@mui/material/TextField";
import {CircularProgress} from "@mui/material";
import {toastWarning} from "../../../core/business/redux/toaster/actions";

export const CommunityInformation = () => {

  useEffect(() => {
    // @ts-ignore
    window._kmq.push(['record', 'User enters community information'])
  }, []);

  let history: any = useHistory()

  const residentData = history.location.state?.residentData

  const unitRef = useRef(null);

  const dispatch = useDispatch()

  const {propertyNames, units} = useSelector((state: StoreModel) => state)

  const [openCommunityNames, setOpenCommunityNames] = useState(false)
  const [openUnits, setOpenUnits] = useState(false)

  const useStyles: any = makeStyles(() => apartmentStyle)

  const classes = useStyles()

  const hostName = document.location.hostname === 'localhost' ? 'trilogy.rllrenters.app' : document.location.hostname;

  const [formData, setFormData] = useState({
    pr_id: residentData?.pr_id || '',
    u_id: residentData?.u_id || '',
    first_name: residentData?.first_name || '',
    last_name: residentData?.last_name || '',
    phone: residentData?.phone || '',
    sms_opt_in: residentData.sms_opt_in,
    email: residentData?.email || '',
    move_in_date: residentData?.move_in_date || moment().format('MM/DD/YYYY')
  })

  useEffect(() => {
    dispatch<any>(getPropertyNamesByUrl(hostName));
  }, [hostName])

  useEffect(() => {
    // @ts-ignore
    dispatch<any>(getUnitList(formData?.pr_id))
    // @ts-ignore
  }, [formData?.pr_id]);

  const warningMessage = () => {
    if (!formData.pr_id) {
      dispatch(toastWarning({className: "small", text: "MESSAGE.REQUIRED_COMMUNITY_NAME"}))
    }
  }

  if (!hostName) return <ErrorComponent errorMessage={'ERROR.NOT_PREV_URL'}/>

  const backIconClick = () => history.push({
    pathname: ADD_YOUR_NAME_ROUTE.path,
    state: {
      prevUrl: COMMUNITY_INFORMATION_ROUTE.path,
      residentData: formData
    }
  })

  const validated: boolean =
    Boolean(formData.pr_id) &&
    Boolean(formData.u_id) &&
    Boolean(formData.first_name) &&
    Boolean(formData.last_name) &&
    Boolean(formData.phone) &&
    validatePhone(formData.phone) &&
    Boolean(formData.email) &&
    validateEmail(formData.email);

  const handleSubmit = () => {
    if (validated) {
      history.push({
        pathname: MOVE_IN_DATE_INFORMATION_ROUTE.path,
        state: {
          prevUrl: COMMUNITY_INFORMATION_ROUTE.path,
          residentData: formData
        }
      })
    }
  }

  const loading = openUnits && !units?.units?.length;

  const selectedName = formData.pr_id && propertyNames
    ? propertyNames.property_names?.filter(el => el.pr_id === formData.pr_id).at(0)
    : null

  const selectedUnit = formData.u_id && units
    ? units.units?.filter(el => el.u_id === formData.u_id).at(0)
    : null

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection
          background={colors.grey}
          previewSteps={true}
          activeStep={1}
          hasBackButton={true}
          backClick={backIconClick}
        />

        <IonGrid className="ion-padding-top ion-padding-bottom pl-0">
          <IonRow className={classes.headingWithIcon}>
            <IonCol className="pl-0 ion-padding-end" size="auto">
              <IonImg className={classes.headingIcon} src={ICON_APARTMENT}/>
            </IonCol>
            <IonCol size="9">
              <h2 className={classes.headingText}>Enter your apartment community information</h2>
            </IonCol>
          </IonRow>
        </IonGrid>

        <Autocomplete
          open={openCommunityNames}
          value={selectedName}
          onOpen={() => setOpenCommunityNames(true)}
          onClose={() => setOpenCommunityNames(false)}
          getOptionLabel={(option) => option.pr_name}
          options={propertyNames?.property_names || []}
          onChange={(_, newValue: any) => {
            // @ts-ignore
            window._kmq.push(['record', `Community name is ${newValue?.pr_name || ''}`])
            setFormData({ ...formData, pr_id: newValue.pr_id, u_id: "" })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Community Name"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        <Autocomplete
          ref={unitRef}
          sx={{marginTop: '40px', marginBottom: '20px'}}
          open={openUnits}
          value={selectedUnit}
          onOpen={() => setOpenUnits(true)}
          onClose={() => setOpenUnits(false)}
          getOptionLabel={(option) => option.unit_no}
          options={units?.units || []}
          loading={loading}
          onChange={(_, newValue: any) => {
            if (formData.pr_id) {
              // @ts-ignore
              window._kmq.push(['record', `Community name is ${newValue?.unit_no || ''}`])
              setFormData({...formData, u_id: newValue.u_id});
            }
          }}
          // onFocus={warningMessage}
          disabled={!formData.pr_id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Unit #"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
          <IonFooter>
            <UniversalButton
              value={"BUTTON.CONTINUE"}
              onClick={handleSubmit}
              disabled={!validated}
              isSubmit={true}
            />
          </IonFooter>
      </Wrapper>
    </IonContent>
  )
}
