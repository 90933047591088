import React, {useEffect, useState} from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {validateEmail, validatePhone} from "../../../core/business/helpers/input";
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {accountSignUp, selfSignUp, syncLocalResident} from "../../business/redux/reducers/resident/actions";
import {toastWarning} from "../../../core/business/redux/toaster/actions";
import {useDispatch, useSelector} from "react-redux";
import {COMMUNITY_INFORMATION_ROUTE, MOVE_IN_DATE_INFORMATION_ROUTE} from "../../business/enums/routes";
import {IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonRow} from "@ionic/react";
import {moveInDateFormStyle} from "./styles";
import {Wrapper} from "../../../core/styles";
import {colors} from "../../business/constants/global";
import moment from "moment";
import {getStorageObj} from "../../../core/business/services/local.storage";
import {StoreModel} from "../../business/models/store";
import {getFormData} from "../functionHelpers";

export const MoveInDateInformation = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    // @ts-ignore
    window._kmq.push(['record', 'User enters move-in date'])
    dispatch<any>(syncLocalResident())
  },[])

  const {resident} = useSelector((s: StoreModel) => s)

  let history: any = useHistory()

  const residentData = history.location.state?.residentData

  const useStyles: any = makeStyles((): any => moveInDateFormStyle);

  const classes = useStyles();

  const [formData, setFormData] = useState(getFormData(residentData))

  const validated =
    Boolean(formData.pr_id) &&
    Boolean(formData.u_id) &&
    Boolean(formData.first_name) &&
    Boolean(formData.last_name) &&
    Boolean(formData.phone) &&
    validatePhone(formData.phone) &&
    Boolean(formData.email) &&
    validateEmail(formData.email) &&
    Boolean(formData.move_in_date);

  const handleSubmit = () => {
    if (validated) {
      // @ts-ignore
      window._kmq.push(['record', `Move in date is ${formData.move_in_date || ''}`])
      // @ts-ignore
      return dispatch(selfSignUp(formData, history))
    } else {
      return dispatch(toastWarning({text: 'Not a Validated'}))
    }
  }

  const backIconClick = () => {
    history.push({
      pathname: COMMUNITY_INFORMATION_ROUTE.path,
      state: {
        prevUrl: MOVE_IN_DATE_INFORMATION_ROUTE.path,
        residentData: formData
      }
    })
  }

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection
          background={colors.grey}
          previewSteps={true}
          activeStep={1}
          hasBackButton={true}
          backClick={backIconClick}
        />

        <IonGrid className="ion-padding-top ion-padding-bottom pl-0">
          <IonRow className="ion-justify-content-center">
            <IonCol size="9">
              <h2 className={classes.headingWithoutIcon}>Choose intended <span className={classes.noWrap}>move-in</span> date</h2>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className={classes.root}>

          <div className={classes.inputGroup}>
            <IonDatetime
              id="datetime"
              min={moment().subtract( 6, 'months').format('YYYY-MM-DD')}
              max={moment().add( 6, 'months').format('YYYY-MM-DD')}
              mode="ios"
              value={moment(formData.move_in_date).format('YYYY-MM-DD')}
              onIonChange={e => setFormData({...formData, move_in_date: moment(e.detail.value).format('MM/DD/YYYY')})}
              presentation="date"
              preferWheel={true}
            >
            </IonDatetime>
          </div>
          <IonFooter>
            <UniversalButton
              value={"BUTTON.CONTINUE"}
              onClick={handleSubmit}
            />
          </IonFooter>
        </div>

      </Wrapper>
    </IonContent>
  )
}
