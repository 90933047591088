import React, {useEffect, useState} from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {QuestionMarkIcon} from "../../components/primitive/icons";
import {validateEmail, validatePhone} from "../../../core/business/helpers/input";
import {UniversalButton} from "../../components/primitive/buttons";
import {InformationModal} from "../../components/primitive/modals";
import {useHistory} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {makeStyles} from "@material-ui/core/styles";
import {checkHasEmail} from "./functionHelpers";
import {getFormData} from "../functionHelpers";
import {useDebounce} from "../../hooks";
import {auth0Configs} from "../../../configs";
import {contactFormStyle} from "./styles";
import {colors} from "../../business/constants/global";
import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText
} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {ICON_EMAIL} from "../../images";
import {
  ADD_YOUR_NAME_ROUTE,
  CONTACT_INFORMATION_ROUTE,
  PERSONAL_ACCESS_ROUTE
} from "../../business/enums/routes";
import Typography from "@mui/material/Typography";

export const ContactInformation = () => {

  useEffect(() => {

    // @ts-ignore
    window._kmq.push(['record', 'User enters contact information (cell and email address)'])
  }, []);

  let history: any = useHistory()

  const residentData = history.location.state?.residentData

  const [formData, setFormData] = useState(getFormData(residentData))

  const debounce = useDebounce(formData.email)

  const [open, setOpen] = useState(false)

  const {loginWithRedirect} = useAuth0()

  const {audience} = auth0Configs

  // @ts-ignore
  const useStyles = makeStyles(() => contactFormStyle);

  const classes = useStyles();

  const requiredText = '* This field is required'
  const requiredPhoneText = '* Invalid phone number'
  const requiredEmailText = '* Invalid email address'
  const existingEmailText = (
    <>
      <p>Seems that you already have an</p>
      <p>account, please
        <span style={{
          color: colors.cyan,
          marginLeft: 8,
          cursor: "pointer",
          textDecoration: "black"
        }} onClick={() => loginWithRedirect({
          connection: 'email',
          login_hint: formData.email
        })}>
        LOG IN here
      </span>
      </p>
    </>
  )

  const [sent, setSent] = useState(false)
  const errorText = (message) => (
    <IonText color="danger" className="ion-margin-top">
      <span role="alert">{message}</span>
    </IonText>
  )

  const validated =
    Boolean(formData.phone) &&
    validatePhone(formData.phone) &&
    Boolean(formData.email) &&
    validateEmail(formData.email);

  const handleSubmit = () => {
    if (validated) {
      // @ts-ignore
      window._kmq.push(['record', `Mobile number is ${formData.phone}`])
      // @ts-ignore
      window._kmq.push(['record', `Email address is ${formData.email}`])
      // @ts-ignore
      window._kmq.push(['record', `Checkbox is ${formData.i_want_to_receive_text_messages ? 'Yes' : 'No'}`])
      history.push({
        pathname: ADD_YOUR_NAME_ROUTE.path,
        state: {
          prevUrl: CONTACT_INFORMATION_ROUTE.path,
          residentData: formData
        }
      })
    } else {
      setSent(true);
    }
  }

  const [isUniqEmail, setIsUniqEmail] = useState(false)

  const existingEmail = validateEmail(formData.email) && isUniqEmail && errorText(existingEmailText)

  useEffect(() => {
    if (!!formData.email && validateEmail(formData.email)) {
      checkHasEmail(formData.email).then(el => el.success && setIsUniqEmail(el.exists));
    }
  }, [debounce])

  const backIconClick = () => {
    history.push({
      pathname: PERSONAL_ACCESS_ROUTE.path,
      state: {
        prevUrl: CONTACT_INFORMATION_ROUTE.path,
        residentData: formData
      }
    })
  }

  const headerText = 'Why do you need my contact info?'
  const contextText = (
    <p>
      We will keep you updated on the approval status of your renters insurance policy.
      Your information will not be used for any other purpose.
    </p>
  )

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection
          background={colors.grey}
          previewSteps={true}
          activeStep={1}
          hasBackButton={true}
          backClick={backIconClick}
        />

        <IonGrid className="ion-padding-top ion-padding-bottom pl-0">
          <IonRow className={classes.headingWithIcon}>
            <IonCol className="pl-0 ion-padding-end" size="auto">
              <IonImg className={classes.headingIcon} src={ICON_EMAIL}/>
            </IonCol>
            <IonCol size="10">
              <h2 className={classes.headingText}>Add your contact <br/></h2>
              <h2 className={classes.headingText}><span>information <QuestionMarkIcon
                onClick={() => setOpen(true)}/></span></h2>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className={classes.root}>
          <div className={classes.inputGroup}>
            <IonLabel>Cell</IonLabel><br/>
            <IonInput
              mode="ios"
              type={'number'}
              value={formData.phone}
              name={"phone"}
              className="ion-border ion-border-radius4"
              onIonInput={(e: any) => setFormData({...formData, phone: e.target.value})}
            />
            {sent && !Boolean(formData.phone) && errorText(requiredText)}
            {sent && Boolean(formData.phone) && !validatePhone(formData.phone) && errorText(requiredPhoneText)}
            <p className='ion-padding-start'>Phone format: 5555551234</p>
          </div>
          <div className={`${isUniqEmail && 'input-no-validate'} ${classes.inputGroup}`}>
            <IonLabel>Email</IonLabel><br/>
            <IonInput
              type={'email'}
              value={formData.email}
              name={"email"}
              className="ion-border ion-border-radius4 custom-ion-input"
              onIonInput={(e: any) => {
                setFormData({...formData, email: e.target.value})
              }}
            />
            {sent && !Boolean(formData.email) && errorText(requiredText)}
            {sent && Boolean(formData.email) && !validateEmail(formData.email) && errorText(requiredEmailText)}
            {existingEmail}
          </div>

          <IonItem>
            {/* @ts-ignore*/}
            <IonCheckbox slot="start" checked={formData.i_want_to_receive_text_messages === 't'} onIonChange={() => setFormData(prev => ({...prev, i_want_to_receive_text_messages: formData.i_want_to_receive_text_messages === 't' ? 'f' : 't'}))}></IonCheckbox>
            <IonLabel>
              <IonText><p style={{fontSize: 16}}>I agree to receive text messages</p></IonText>
              <IonText><p style={{fontSize: 16}}>regarding status of my renters insurance</p></IonText>
            </IonLabel>
          </IonItem>
        </div>
        <IonFooter>
          <UniversalButton
            disabled={!!existingEmail || !validateEmail(formData.email)}
            value={"BUTTON.CONTINUE"}
            onClick={handleSubmit}
            isSubmit={true}
          />
        </IonFooter>

        <InformationModal
          isOpen={open}
          setIsOpen={setOpen}
          headerText={headerText}
          contextText={contextText}
        />

      </Wrapper>
    </IonContent>
  )
}
